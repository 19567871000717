import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import * as Styled from "./node-buttons.style";
import { CanvasOverlay } from "./canvas-overlay";
import { EditButtons } from "ui/components/elements/edit-buttons/edit-buttons";
import { useNodeActions } from "hooks/node-actions";
export var NodeButtons = function (_a) {
    var _b = _a.isZoomedIn, isZoomedIn = _b === void 0 ? false : _b, nodeBoundingBoxHeight = _a.nodeBoundingBoxHeight, nodeTopY = _a.nodeTopY, nodeCenterX = _a.nodeCenterX, node = _a.node;
    var actions = useNodeActions(node);
    var topActions = actions.filter(function (action) { return action !== undefined && action.isDelete; });
    var bottomActions = actions.filter(function (action) { return action !== undefined && !action.isDelete; });
    var buttons = isZoomedIn ? (_jsx(Styled.NodeButtons, { nodeBoundingBoxHeight: nodeBoundingBoxHeight, children: _jsx(EditButtons, { actions: actions, hasBackground: true }) })) : (_jsxs(Styled.NodeButtons, { nodeBoundingBoxHeight: nodeBoundingBoxHeight, children: [_jsx(EditButtons, { hasBackground: false, className: "".concat(Styled.prefix, "-top-buttons"), actions: topActions }), _jsx("div", { className: "".concat(Styled.prefix, "-bbox") }), _jsx(EditButtons, { hasBackground: isZoomedIn, actions: bottomActions })] }));
    return (_jsx(CanvasOverlay, { x: nodeCenterX, y: nodeTopY, children: buttons }));
};
