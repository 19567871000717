var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import * as Styled from "./horizontal-zoom-slider.style";
import { indexOfClosest } from "./graph-data-util";
import { useGraphDataContext } from "providers/graph-panel";
import { selectors, useAppSelector } from "store";
import RangeSlider from "ui/components/elements/range-slider/range-slider";
var sliderColor = "primary";
var sliderSteps = 100;
export var HorizontalZoomSlider = function () {
    var _a = useGraphDataContext(), dataIndexLeft = _a.dataIndexLeft, dataIndexRight = _a.dataIndexRight, horizontalZoom = _a.horizontalZoom, zoomedTimeDomain = _a.zoomedTimeDomain;
    var graphData = useAppSelector(selectors.selectGraphData);
    var timeData = graphData.systemProperty.timeData;
    var _b = __read(useState(undefined), 2), initialSliderIndexLeft = _b[0], setInitialSliderIndexLeft = _b[1];
    var _c = __read(useState(undefined), 2), initialSliderIndexRight = _c[0], setInitialSliderIndexRight = _c[1];
    var _d = __read(useState({
        min: undefined,
        max: undefined,
    }), 2), sliderRange = _d[0], setSliderRange = _d[1];
    var min = timeData[0];
    var max = timeData[timeData.length - 1];
    var step = (max - min) / sliderSteps;
    var handleSliderRangeChange = function (min, max) {
        setSliderRange({
            min: indexOfClosest(timeData, min),
            max: indexOfClosest(timeData, max),
        });
    };
    useDebounce(function () {
        var min = sliderRange.min, max = sliderRange.max;
        if (min === undefined || max === undefined) {
            return;
        }
        horizontalZoom(min, max);
    }, 100, [sliderRange]);
    useEffect(function () {
        setInitialSliderIndexLeft(function (currentValue) {
            if (dataIndexLeft === undefined) {
                return undefined;
            }
            if (currentValue !== undefined) {
                return currentValue;
            }
            return dataIndexLeft;
        });
        setInitialSliderIndexRight(function (currentValue) {
            if (dataIndexRight === undefined) {
                return undefined;
            }
            if (currentValue !== undefined) {
                return currentValue;
            }
            return dataIndexRight;
        });
    }, [dataIndexLeft, dataIndexRight]);
    // If the user has not zoomed in yet, we don't render this component
    if (!zoomedTimeDomain ||
        dataIndexLeft === undefined ||
        dataIndexRight === undefined ||
        initialSliderIndexLeft === undefined ||
        initialSliderIndexRight === undefined) {
        return null;
    }
    return (_jsx(Styled.HorizontalZoomSliderRoot, { color: sliderColor, children: _jsx(RangeSlider, { min: min, max: max, step: step, initialMinValue: timeData[dataIndexLeft], initialMaxValue: timeData[dataIndexRight], onChange: handleSliderRangeChange, minDistance: 1, round: true }) }));
};
