import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";
use(initReactI18next).init({
    fallbackLng: "en",
    lng: "en",
    resources: {
        en: {
            global: require("./locales/en/global.json"),
        },
    },
    ns: ["global"],
    defaultNS: "global",
    keySeparator: ".",
});
i18n.languages = ["en"];
console.log(i18n);
export default i18n;
