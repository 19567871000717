var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Tooltip, StyledTooltip } from "@animech/ui";
import { ToggleIcon } from "ui/components/elements/messages-popover/messages-popover.style";
export var prefix = "node-performance-tooltip";
export var UITooltip = styled(Tooltip)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var TooltipTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: column;\n"], ["\n\tdisplay: flex;\n\tflex-direction: column;\n"])));
export var Name = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tfont-weight: 300;\n\tfont-size: 12px;\n"], ["\n\tfont-weight: 300;\n\tfont-size: 12px;\n"])));
export var PerformanceTooltipWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, align = _a.align, shouldFadeTooltip = _a.shouldFadeTooltip;
    var isTooltipInComponentDataList = align === undefined;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\t\t", "\n\t\t\ttransition: 0.1s ease-out;\n\t\t\tpointer-events: all;\n\n\t\t\t", " {\n\t\t\t\t", " {\n\t\t\t\t\tbackground-color: ", " !important;\n\t\t\t\t\tpadding: 8px 18px 14px;\n\t\t\t\t}\n\t\t\t\t/* To adjust the position of the info icon closer to edge without affecting text spacing. */\n\t\t\t\t", " {\n\t\t\t\t\tmargin-right: -10px;\n\t\t\t\t\tmargin-top: -5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.", "-tooltip-title {\n\t\t\t\tmin-height: 35px;\n\t\t\t\tpointer-events: all;\n\t\t\t\tdisplay: flex;\n\t\t\t\tfont-weight: bold;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: space-between;\n\t\t\t}\n\n\t\t\t", "\n\t\t"], ["\n\t\t\t", "\n\t\t\ttransition: 0.1s ease-out;\n\t\t\tpointer-events: all;\n\n\t\t\t", " {\n\t\t\t\t", " {\n\t\t\t\t\tbackground-color: ", " !important;\n\t\t\t\t\tpadding: 8px 18px 14px;\n\t\t\t\t}\n\t\t\t\t/* To adjust the position of the info icon closer to edge without affecting text spacing. */\n\t\t\t\t", " {\n\t\t\t\t\tmargin-right: -10px;\n\t\t\t\t\tmargin-top: -5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.", "-tooltip-title {\n\t\t\t\tmin-height: 35px;\n\t\t\t\tpointer-events: all;\n\t\t\t\tdisplay: flex;\n\t\t\t\tfont-weight: bold;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: space-between;\n\t\t\t}\n\n\t\t\t", "\n\t\t"])), !isTooltipInComponentDataList && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t\t\t\ttransform: translateY(-50%)\n\t\t\t\t\t", ";\n\t\t\t\tmargin-left: ", "px;\n\t\t\t"], ["\n\t\t\t\ttransform: translateY(-50%)\n\t\t\t\t\t", ";\n\t\t\t\tmargin-left: ", "px;\n\t\t\t"])), align === "left" && "translateX(calc(-100% - 40px))", align === "left" ? 0 : 40), UITooltip, StyledTooltip.Content, theme.colors.palette.grey[800], ToggleIcon, prefix, shouldFadeTooltip && "opacity: 15%;  pointer-events: none; ");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
