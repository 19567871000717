export var CardboardModelId = {
    CORNER_BOTTOM_LEFT: "TestObj-CardboardBox01CornerBottomleft",
    CORNER_BOTTOM_RIGHT: "TestObj-CardboardBox01CornerBottomright",
    CORNER_TOP_LEFT: "TestObj-CardboardBox01CornerTopleft",
    CORNER_TOP_RIGHT: "TestObj-CardboardBox01CornerTopright",
    EDGE_BOTTOM_LEFT: "TestObj-CardboardBox01EdgeBottomleft",
    EDGE_BOTTOM_RIGHT: "TestObj-CardboardBox01EdgeBottomright",
    EDGE_MIDDLE_LEFT: "TestObj-CardboardBox01EdgeMiddleleft",
    EDGE_TOP_RIGHT: "TestObj-CardboardBox01EdgeTopright",
    EDGE_TOP_LEFT: "TestObj-CardboardBox01EdgeTopleft",
    FILL_LEFT: "TestObj-CardboardBox01FillLeft",
    FILL_RIGHT: "TestObj-CardboardBox01FillRight",
    SEAM_MIDDLE: "TestObj-CardboardBox01SeamMiddle",
    SEAM_BOTTOM: "TestObj-CardboardBox01SeamBottom",
    SEAM_TOP: "TestObj-CardboardBox01SeamTop",
    TAPE_START: "TestObj-CardboardBox01TapeStart",
    TAPE_MIDDLE: "TestObj-CardboardBox01TapeMiddle",
    TAPE_EDGE_CORNER: "TestObj-CardboardBox01TapeEdgeCorner",
    TAPE_EDGE_BOTTOM: "TestObj-CardboardBox01TapeEdgeBottom",
    TAPE_EDGE_SIDE: "TestObj-CardboardBox01TapeEdgeSide",
};
