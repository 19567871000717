import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { TooltipPortal, Tooltip } from "@animech/ui";
export var Interval = function (_a) {
    var x = _a.x, y = _a.y, width = _a.width, height = _a.height, color = _a.color, tooltipText = _a.tooltipText, _b = _a.roundedLeft, roundedLeft = _b === void 0 ? false : _b, _c = _a.roundedRight, roundedRight = _c === void 0 ? false : _c;
    if (!width)
        return null;
    var hoverStyle = {
        "&:hover": {
            cursor: "pointer",
            fillOpacity: "50%",
        },
    };
    return (_jsxs("g", { children: [_jsx("defs", { children: _jsx("clipPath", { id: "rounded-left-".concat(x, "-").concat(y), children: _jsx("rect", { x: roundedLeft ? x : x - 5, y: y, width: roundedRight ? width + (roundedLeft ? 0 : 5) : width + 10, height: height, ry: 5, rx: 5 }) }) }), _jsx(TooltipPortal, { anchorElement: _jsx("rect", { css: hoverStyle, x: x, y: y, width: width, height: height, fill: color, clipPath: "url(#rounded-left-".concat(x, "-").concat(y, ")") }), toggleEvent: "click", placement: "top", children: _jsx(Tooltip, { children: tooltipText }) })] }));
};
