var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledButton } from "@animech/ui";
export var prefix = "edit-buttons";
export var EditButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\ttext-align: center;\n\t\t\tz-index: 3;\n\t\t\tpointer-events: auto;\n\n\t\t\t.", "-with-background {\n\t\t\t\tpadding: 5px;\n\t\t\t\tborder-radius: ", ";\n\t\t\t\tbackground: rgba(0, 0, 0, 0.5);\n\t\t\t}\n\n\t\t\t.", "-large {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: center;\n\t\t\t\tpadding: 10px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin: 15px;\n\t\t\t\t\tpadding: 12px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbox-shadow: 1px 1px 6px ", " !important;\n\t\t\t}\n\n\t\t\tspan {\n\t\t\t\tdisplay: inline-block;\n\t\t\t}\n\t\t"], ["\n\t\t\ttext-align: center;\n\t\t\tz-index: 3;\n\t\t\tpointer-events: auto;\n\n\t\t\t.", "-with-background {\n\t\t\t\tpadding: 5px;\n\t\t\t\tborder-radius: ", ";\n\t\t\t\tbackground: rgba(0, 0, 0, 0.5);\n\t\t\t}\n\n\t\t\t.", "-large {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: center;\n\t\t\t\tpadding: 10px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\t", " {\n\t\t\t\t\tmargin: 15px;\n\t\t\t\t\tpadding: 12px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbox-shadow: 1px 1px 6px ", " !important;\n\t\t\t}\n\n\t\t\tspan {\n\t\t\t\tdisplay: inline-block;\n\t\t\t}\n\t\t"])), prefix, theme.dimensions.borderRadius, prefix, StyledButton.Root, StyledButton.Root, theme.colors.palette.grey[500]);
});
var templateObject_1, templateObject_2;
