var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { useState, useCallback, useRef } from "react";
import * as Styled from "./range-slider.style";
var DraggingRef;
(function (DraggingRef) {
    DraggingRef["MIN"] = "min";
    DraggingRef["MAX"] = "max";
    DraggingRef["TRACK"] = "tracl";
})(DraggingRef || (DraggingRef = {}));
var RangeSlider = function (_a) {
    var min = _a.min, max = _a.max, step = _a.step, initialMinValue = _a.initialMinValue, initialMaxValue = _a.initialMaxValue, minDistance = _a.minDistance, round = _a.round, onChange = _a.onChange;
    var _b = __read(useState(initialMinValue), 2), currentMinValue = _b[0], setCurrentMinValue = _b[1];
    var _c = __read(useState(initialMaxValue), 2), currentMaxValue = _c[0], setCurrentMaxValue = _c[1];
    var containerRef = useRef(null);
    var draggingRef = useRef(null);
    var startXRef = useRef(0);
    var startMinValueRef = useRef(initialMinValue);
    var startMaxValueRef = useRef(initialMaxValue);
    var getPercentage = useCallback(function (value) { return ((value - min) / (max - min)) * 100; }, [min, max]);
    var handleMouseMove = function (e) {
        if (!draggingRef.current || !containerRef.current)
            return;
        e.preventDefault();
        var rect = containerRef.current.getBoundingClientRect();
        var deltaX = ((e.clientX - startXRef.current) / rect.width) * (max - min);
        var stepAdjustedDelta = Math.round(deltaX / step) * step;
        if (draggingRef.current === DraggingRef.MIN) {
            var newMinValue = startMinValueRef.current + stepAdjustedDelta;
            if (newMinValue < min)
                newMinValue = min;
            if (newMinValue > currentMaxValue - minDistance)
                newMinValue = currentMaxValue - minDistance;
            setCurrentMinValue(newMinValue);
            onChange(newMinValue, currentMaxValue);
        }
        else if (draggingRef.current === DraggingRef.MAX) {
            var newMaxValue = startMaxValueRef.current + stepAdjustedDelta;
            if (newMaxValue > max)
                newMaxValue = max;
            if (newMaxValue < currentMinValue + minDistance)
                newMaxValue = currentMinValue + minDistance;
            setCurrentMaxValue(newMaxValue);
            onChange(currentMinValue, newMaxValue);
        }
        else if (draggingRef.current === DraggingRef.TRACK) {
            var newMinValue = startMinValueRef.current + stepAdjustedDelta;
            var newMaxValue = startMaxValueRef.current + stepAdjustedDelta;
            if (newMinValue < min) {
                newMinValue = min;
                newMaxValue =
                    min + (startMaxValueRef.current - startMinValueRef.current);
            }
            else if (newMaxValue > max) {
                newMaxValue = max;
                newMinValue =
                    max - (startMaxValueRef.current - startMinValueRef.current);
            }
            setCurrentMinValue(newMinValue);
            setCurrentMaxValue(newMaxValue);
            onChange(newMinValue, newMaxValue);
        }
    };
    var handleMouseUp = function () {
        draggingRef.current = null;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };
    var handleMouseDown = function (thumbType) { return function (e) {
        e.preventDefault();
        draggingRef.current = thumbType;
        startXRef.current = e.clientX;
        startMinValueRef.current = currentMinValue;
        startMaxValueRef.current = currentMaxValue;
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    }; };
    var thumbPositionLeft = getPercentage(currentMinValue);
    var thumbPositionRight = getPercentage(currentMaxValue);
    return (_jsxs(Styled.RangeSliderRoot, { ref: containerRef, left: thumbPositionLeft, right: thumbPositionRight, width: thumbPositionRight - thumbPositionLeft, children: [_jsx(Styled.SliderTrack, { onMouseDown: handleMouseDown(DraggingRef.TRACK) }), _jsx(Styled.SliderThumb, { "data-left": true, onMouseDown: handleMouseDown(DraggingRef.MIN) }), _jsx(Styled.Label, { "data-left": true, children: round ? currentMinValue.toFixed(2) : currentMinValue }), _jsx(Styled.SliderThumb, { "data-right": true, onMouseDown: handleMouseDown(DraggingRef.MAX) }), _jsx(Styled.Label, { "data-right": true, children: round ? currentMaxValue.toFixed(2) : currentMaxValue })] }));
};
export default RangeSlider;
