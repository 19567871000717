var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSelect, ImageSelectItem, Label } from "@animech/ui";
import { isEqual } from "lodash";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { EditButtons } from "ui/components/elements/edit-buttons/edit-buttons";
import { GripPositionMarkerId } from "store/types";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { checkGripPositionsFit, isMountingCompatibleWithSuctionCup, } from "3d/helpers";
import { gripPositionMarkerModels } from "3d/constants/common";
import { Dropdown } from "ui/components/elements/input/dropdown";
import { Unit } from "utility/number";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { KeyBindings, useKeyBindings } from "hooks/keybindings";
import { useNodeActions } from "hooks/node-actions";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
import { CustomSlider } from "ui/components/elements/custom-slider/custom-slider";
import { MultipleValuesDropdown } from "ui/components/elements/input/multiple-values-dropdown";
export var GripPositionMenu = function (_a) {
    var nodes = _a.nodes;
    var t = useTranslation().t;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var dispatch = useAppDispatch();
    var millimeterNumberConverter = useNumberConversionWithMillimeters();
    var checklist = useAppSelector(selectors.selectChecklist);
    var isSelectCupDone = checklist.select_cup.completed;
    var isAddPumpDone = checklist.add_pump.completed;
    // useKeyBindings only works with one node, so we bind the first node
    useKeyBindings(nodes[0], [KeyBindings.DELETE, KeyBindings.ESCAPE]);
    var convertNumberWithMillimeters = useNumberConversionWithMillimeters();
    var gripPositions = nodes.map(function (node) { return node.component; });
    var node = nodes[0];
    var gripPosition = gripPositions[0];
    var gripPositionModel = gripPositionMarkerModels[gripPosition.marker];
    var cupFamilyOptions = useMemo(function () {
        if (!(systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupComponents))
            return [];
        var cupFamilyOptionsList = Object.values(systemDataDefineParameters.suctionCupComponents)
            .filter(function (component) { return component.valid; })
            .map(function (item) { return item.cupFamily; })
            .filter(function (item, index, self) { return self.indexOf(item) === index && item !== ""; });
        return cupFamilyOptionsList;
    }, [systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupComponents]);
    var currentlySelectedCup = useAppSelector(selectors.selectSelectedCup(node.id));
    var nodeIds = useMemo(function () { return nodes.map(function (node) { return node.id; }); }, [nodes]);
    var currentlySelectedCups = useAppSelector(selectors.selectSelectedCups(nodeIds));
    var _b = __read(useState([]), 2), currentlySelectedCupFamilies = _b[0], setCurrentlySelectedCupFamilies = _b[1];
    // update cup families when selected cups change
    var _c = __read(useState([]), 2), prevCups = _c[0], setPrevCups = _c[1];
    if (!isEqual(prevCups, currentlySelectedCups)) {
        var defaultCupFamily_1 = cupFamilyOptions[0];
        var cupFamilies = currentlySelectedCups.map(function (cup) { return (cup === null || cup === void 0 ? void 0 : cup.cupFamily) || defaultCupFamily_1; });
        setCurrentlySelectedCupFamilies(cupFamilies);
        setPrevCups(currentlySelectedCups);
    }
    var cupOptions = useMemo(function () {
        if (!(systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupComponents))
            return [];
        var cupOptionsList = Object.values(systemDataDefineParameters.suctionCupComponents)
            .filter(function (component) {
            return (component.valid ||
                component.value === (currentlySelectedCup === null || currentlySelectedCup === void 0 ? void 0 : currentlySelectedCup.value)) &&
                (!currentlySelectedCupFamilies.length ||
                    currentlySelectedCupFamilies.includes(component.cupFamily) ||
                    component.cupFamily === "");
        })
            .map(function (component) { return (__assign(__assign({}, component), { isDisabled: !checkGripPositionsFit(gripPositions, component.width, component.height) })); })
            .sort(function (c1, c2) {
            /* Put disabled cups last in list */
            if (c1.isDisabled && !c2.isDisabled)
                return 1;
            if (!c1.isDisabled && c2.isDisabled)
                return -1;
            /* Order with smallest width first */
            return c1.width > c2.width ? 1 : -1;
        });
        return cupOptionsList;
    }, [
        systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupComponents,
        currentlySelectedCup === null || currentlySelectedCup === void 0 ? void 0 : currentlySelectedCup.value,
        currentlySelectedCupFamilies,
        gripPositions,
    ]);
    var currentlySelectedCupMounting = useAppSelector(selectors.selectSelectedCupMounting(node.id));
    var cupMountingOptions = useMemo(function () {
        if (!(systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.cupMountingComponents))
            return [];
        var cupMountingOptionsList = Object.values(systemDataDefineParameters.cupMountingComponents).filter(function (component) {
            return isMountingCompatibleWithSuctionCup(currentlySelectedCup, component) ||
                component.value === (currentlySelectedCupMounting === null || currentlySelectedCupMounting === void 0 ? void 0 : currentlySelectedCupMounting.value);
        });
        return cupMountingOptionsList;
    }, [
        currentlySelectedCup,
        currentlySelectedCupMounting === null || currentlySelectedCupMounting === void 0 ? void 0 : currentlySelectedCupMounting.value,
        systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.cupMountingComponents,
    ]);
    var onChangeMarkerType = function (value) {
        dispatch(actions.setGripPositionMarker({
            nodeId: node.id,
            marker: value,
        }));
    };
    var onChangeWidth = function (width) {
        dispatch(actions.setGripPositionWidth({
            nodeId: node.id,
            width: width,
        }));
    };
    var onChangeTubeLength = function (length) {
        dispatch(actions.setGripPositionTubeLength({
            nodeId: node.id,
            length: length,
        }));
    };
    var onChangeTubeDiameter = function (diameter) {
        dispatch(actions.setGripPositionTubeDiameter({
            nodeId: node.id,
            diameter: diameter,
        }));
    };
    var onSelectCupFamily = function (cupFamily) {
        setCurrentlySelectedCupFamilies([cupFamily]);
    };
    var onSelectCup = function (cup) {
        dispatch(actions.selectCup({ nodeIds: nodes.map(function (_a) {
                var id = _a.id;
                return id;
            }), value: cup }));
    };
    var onSelectPump = function (pump) {
        dispatch(actions.selectDecentralPump({ nodeId: node.id, value: pump }));
    };
    var onSelectCupMounting = function (cupMounting) {
        dispatch(actions.selectCupMounting({ nodeId: node.id, value: cupMounting }));
    };
    var currentlySelectedPump = useAppSelector(selectors.selectSelectedDecentralPump(node.id));
    var pumpOptions = useMemo(function () {
        if (!(systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.vacuumPumpComponents))
            return [];
        return Object.values(systemDataDefineParameters.vacuumPumpComponents).filter(function (component) {
            return component.valid || component.value === (currentlySelectedPump === null || currentlySelectedPump === void 0 ? void 0 : currentlySelectedPump.value);
        });
    }, [currentlySelectedPump, systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.vacuumPumpComponents]);
    var nodeActions = useNodeActions(node);
    var cupFamilyDropdownImages = useMemo(function () {
        if (!systemDataDefineParameters)
            return [];
        var cupFamilies = cupFamilyOptions.map(function (family) {
            var _a;
            return Object.values((_a = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupComponents) !== null && _a !== void 0 ? _a : {}).find(function (component) { return component.image && component.cupFamily === family; });
        });
        var cupFamilyImages = cupFamilies.map(function (family) { return family === null || family === void 0 ? void 0 : family.image; });
        return cupFamilyImages;
    }, [cupFamilyOptions, systemDataDefineParameters]);
    var markerSizeMinValue = gripPositionModel.diameterRange[0];
    var markerSizeMaxValue = gripPositionModel.diameterRange[1];
    var convertedMinMarkerSize = convertNumberWithMillimeters(markerSizeMinValue, Unit.M);
    var convertedMaxMarkerSize = convertNumberWithMillimeters(markerSizeMaxValue, Unit.M);
    if (isMultiSelecting) {
        var selectedCupsValuesAreTheSame = currentlySelectedCups.every(function (cup) { var _a; return (cup === null || cup === void 0 ? void 0 : cup.value) === ((_a = currentlySelectedCups === null || currentlySelectedCups === void 0 ? void 0 : currentlySelectedCups[0]) === null || _a === void 0 ? void 0 : _a.value); });
        var selectedCupForMultiSelection = selectedCupsValuesAreTheSame
            ? currentlySelectedCups[0]
            : undefined;
        return (_jsxs(_Fragment, { children: [_jsx(SelectCupFamilySection, { values: currentlySelectedCupFamilies, onValueChange: onSelectCupFamily, options: cupFamilyOptions, images: cupFamilyDropdownImages }), _jsx(SelectCupSection, { value: selectedCupForMultiSelection === null || selectedCupForMultiSelection === void 0 ? void 0 : selectedCupForMultiSelection.value, onValueChange: onSelectCup, options: cupOptions, highlightSection: !isSelectCupDone })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(EditButtons, { className: "".concat(Styled.prefix, "-edit-buttons"), buttonSize: "large", actions: nodeActions }), _jsxs(Styled.SidebarSection, { children: [_jsxs(Label, { children: [t("sidebar.edit_grip_position.marker_type_label"), " "] }), _jsx(ImageSelect, { className: "".concat(Styled.prefix, "-image-select"), itemImages: Object.values(GripPositionMarkerId).map(function (positionId) { return gripPositionMarkerModels[positionId].image; }), itemLabels: Object.values(GripPositionMarkerId).map(function (positionId) {
                            return t(gripPositionMarkerModels[positionId].translationKey);
                        }), items: Object.values(GripPositionMarkerId), value: gripPosition.marker, onValueChange: onChangeMarkerType, gridItemSize: 3, gridSpacing: "large" })] }), _jsx(Styled.SidebarSection, { children: _jsx(ConvertedNumberInput, { label: t("sidebar.edit_grip_position.marker_size_label", {
                        minValue: convertedMinMarkerSize.value,
                        maxValue: convertedMaxMarkerSize.value,
                        unit: convertedMinMarkerSize.unit,
                    }), setValue: onChangeWidth, unit: Unit.M, min: markerSizeMinValue, max: markerSizeMaxValue, value: gripPosition.width, type: "float", customConverter: millimeterNumberConverter, instantUpdate: true }) }), !isCentralizedSystem && (_jsxs(Styled.SidebarSection, { shouldHighlight: !isAddPumpDone, children: [_jsx(Dropdown, { label: t("sidebar.edit_grip_position.select_pump"), items: pumpOptions.map(function (component) { return component.value; }), itemLabels: pumpOptions.map(function (component) { return component.label; }), value: currentlySelectedPump === null || currentlySelectedPump === void 0 ? void 0 : currentlySelectedPump.value, onValueChange: onSelectPump }), (currentlySelectedPump === null || currentlySelectedPump === void 0 ? void 0 : currentlySelectedPump.isInlineDecentralPump) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "".concat(Styled.prefix, "-nested-section"), children: _jsx("div", { className: "".concat(Styled.prefix, "-text-input"), children: _jsx(ConvertedNumberInput, { label: t("sidebar.edit_grip_position.inline_tube_length"), setValue: onChangeTubeLength, unit: Unit.M, min: 0, max: 20, value: gripPosition.inlineTubeLength, type: "float", instantUpdate: true }) }) }), _jsx("div", { className: "".concat(Styled.prefix, "-nested-section"), children: _jsx(CustomSlider, { header: t("sidebar.edit_grip_position.inline_tube_diameter"), min: 0.001, max: 0.008, stepSize: 0.001, showSteps: false, showValue: false, value: gripPosition.inlineTubeDiameter, onValueChange: onChangeTubeDiameter, instantValueChange: true, color: "primary", labelLeft: convertNumberWithMillimeters(gripPosition.inlineTubeDiameter, Unit.M).formatted }) })] }))] })), _jsx(Styled.SidebarSection, { children: _jsx(Dropdown, { label: t("sidebar.edit_grip_position.select_cup_mounting"), description: {
                        long: t("descriptions.sidebar.edit_grip_position.select_cup_mounting"),
                    }, items: cupMountingOptions.map(function (component) { return component.value; }), itemLabels: cupMountingOptions.map(function (component) { return component.label; }), value: currentlySelectedCupMounting === null || currentlySelectedCupMounting === void 0 ? void 0 : currentlySelectedCupMounting.value, onValueChange: onSelectCupMounting }) }), _jsx(SelectCupFamilySection, { values: currentlySelectedCupFamilies, onValueChange: onSelectCupFamily, options: cupFamilyOptions, images: cupFamilyDropdownImages }), _jsx(SelectCupSection, { value: currentlySelectedCup === null || currentlySelectedCup === void 0 ? void 0 : currentlySelectedCup.value, onValueChange: onSelectCup, options: cupOptions, highlightSection: !isSelectCupDone })] }));
};
var SelectCupSection = function (_a) {
    var value = _a.value, onValueChange = _a.onValueChange, options = _a.options, highlightSection = _a.highlightSection;
    var t = useTranslation().t;
    return (_jsxs(Styled.SidebarSection, { shouldHighlight: highlightSection, children: [_jsx(Label, { children: t("sidebar.edit_grip_position.select_cup") }), _jsx(ImageSelect, { className: "".concat(Styled.prefix, "-image-select"), items: options.map(function (component) { return component.value; }), itemLabels: options.map(function (component) { return component.label; }), itemImages: options.map(function (component) { return component.image; }), value: value, onValueChange: onValueChange, gridItemSize: 3, gridSpacing: "small", renderItem: function (props, index) {
                    return (_jsx(ImageSelectItem, __assign({}, props, { disabled: options[index].isDisabled })));
                } })] }));
};
var SelectCupFamilySection = function (_a) {
    var values = _a.values, onValueChange = _a.onValueChange, options = _a.options, images = _a.images;
    var t = useTranslation().t;
    return (_jsx(Styled.SidebarSection, { children: _jsx(MultipleValuesDropdown, { label: t("sidebar.edit_grip_position.select_cup_family"), items: options, itemImages: images, itemLabels: options, values: values, onValueChange: onValueChange }) }));
};
